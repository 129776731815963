import * as React from 'react';
import { Icon, SimApplication, SimIcon } from 'ui-library';

type Props = {
    application: SimApplication
    size?: string
};

const AppIcon = (props: Props) => {
    const { application, size } = props;
    switch (application) {
        case SimApplication.HEALTH_DASHBOARD: {
            return <SimIcon icon={Icon.HEALTH}
                            size={size} />;
        }
        case SimApplication.LCC: {
            return <SimIcon icon={Icon.LCC}
                            size={size} />;
        }
        case SimApplication.MASTER_DATA: {
            return <SimIcon icon={Icon.STAMMDATEN}
                            size={size} />;
        }
        case SimApplication.PORTAL: {
            return <SimIcon icon={Icon.DASHBOARD}
                            size={size} />;
        }
        case SimApplication.SEARCH: {
            return <SimIcon icon={Icon.DATENDREHSCHEIBE}
                            size={size} />;
        }
        case SimApplication.DOPS: {
            return <SimIcon icon={Icon.DOPS}
                            size={size} />;
        }
        case SimApplication.REPORTING: {
            return <SimIcon icon={Icon.REPORTING}
                            size={size} />;
        }
        case SimApplication.NETWORK_MANAGEMENT: {
            return <SimIcon icon={Icon.NETZWERKMANAGEMENT}
                            size={size} />;
        }
        default: {
            return <></>;
        }
    }
};

export { AppIcon };
