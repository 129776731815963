import axios from 'axios';
import { msalConfig_prod, msalConfig_prod_partner, msalConfig_qa, msalConfig_qa_partner, tokenRequest_prod, tokenRequest_qa } from '../security';
import { B2CPolicies, b2cPoliciesProd, b2cPoliciesProdPartner, b2cPoliciesQa, b2cPoliciesQaPartner, baseMsalConfig, baseTokenRequest } from 'ui-library';

export const EnvironmentService = {
    env: 'qa',
    isPartner: false,

    async fetchCurrentEnvironment(): Promise<string> {
        this.isPartner = window.location.hostname.includes('partner');
        const response = await axios.get(`/portal/api/environment`);
        EnvironmentService.env = response.data;
        return EnvironmentService.env;
    },

    getMsalConfig(): typeof baseMsalConfig  {
        if (EnvironmentService.env === 'prod') {
            if (EnvironmentService.isPartner) {
                return msalConfig_prod_partner;
            }
            return msalConfig_prod;
        }
        if (EnvironmentService.isPartner) {
            return msalConfig_qa_partner;
        }
        return msalConfig_qa;
    },

    getTokenRequest(): typeof baseTokenRequest {
        if (EnvironmentService.env === 'prod') {
            return tokenRequest_prod;
        }
        return tokenRequest_qa;
    },

    getB2CPolicies(): B2CPolicies {
        if (EnvironmentService.env === 'prod') {
            if (EnvironmentService.isPartner) {
                return b2cPoliciesProdPartner;
            }
            return b2cPoliciesProd;
        }
        if (EnvironmentService.isPartner) {
            return b2cPoliciesQaPartner;
        }
        return b2cPoliciesQa;
    },
};
