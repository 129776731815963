import { ApplicationLink } from './ApplicationLink';
import { Grid } from '@mui/material';
import { SimApplication, useSimLogin } from 'ui-library';

type Props = {
    displayAs?: 'grid' | 'list'
    className?: string
};

const APPLICATIONS = [
    SimApplication.SEARCH,
    SimApplication.MASTER_DATA,
    SimApplication.LCC,
    SimApplication.DOPS,
    SimApplication.REPORTING,
    SimApplication.NETWORK_MANAGEMENT,
    SimApplication.HEALTH_DASHBOARD,
];

const AvailableApplications = (props: Props) => {
    const { className, displayAs = 'list' } = props;
    const { userProfile } = useSimLogin();

    const availableApplications = APPLICATIONS.filter(application => application === SimApplication.HEALTH_DASHBOARD
                                                                     || userProfile.availableApps?.some(app => app === application));

    if (displayAs === 'list') {
        return (
            <>
                {availableApplications.map(application => (
                    <ApplicationLink key={application}
                                     application={application}
                                     className={className} />
                ))}
            </>
        );
    } else {
        return (
            <Grid container
                  spacing={2}>
                {availableApplications.map(application => (
                    <Grid key={application}
                          item
                          xs={12}
                          sm={6}
                          lg={4}>
                        <ApplicationLink application={application}
                                         className={className} />
                    </Grid>
                ))}
            </Grid>
        );
    }
};

export { AvailableApplications };
